import { range } from 'lodash'
import React from 'react'
import { Basketball, Football, Volleyball } from './ball-icons'

/*
  Render a random ball (basketball, volleyball, football) of a given diameter given the position of the header
  The math at the start of this calculates a random arc for the ball to fly across that passes through the center
  of the screen. The effect moves the absolute position of the ball and rotates it.
*/
function Ball({ ballDiameter, navBarPixels, detach }) {
  const ref = React.useRef()

  const topOfArcYMustBeAbove = ((2 / 3) * window.innerHeight) - navBarPixels

  const topOfArcY = navBarPixels + (0.5 * ballDiameter) + ((topOfArcYMustBeAbove - navBarPixels) * Math.random())
  const topOfArcX = (0.25 + (0.5 * Math.random())) * window.innerWidth
  const direction = Math.random() > 0.5 ? 1 : -1
  const initialX = direction === 1 ? (-ballDiameter) : window.innerWidth

  const millisecondsToFlyAcrossScreen = 1500 + (600 * Math.random())

  const xDistanceToTravel = direction * (window.innerWidth + ballDiameter)
  const xSpeed = xDistanceToTravel / millisecondsToFlyAcrossScreen
  const reachesArcAtTime = (topOfArcX - initialX) / xSpeed

  const gravity = 0.00025
  const ySpeed = -2 * gravity * reachesArcAtTime
  const initialY = topOfArcY - (gravity * reachesArcAtTime * reachesArcAtTime) - (ySpeed * reachesArcAtTime)

  const rotationSpeed = 0.2 + (0.8 * Math.random())

  let detached = false

  React.useEffect(() => {
    let startTime

    function animate() {
      if (detached) {
        return ref.current.innerHTML = ''
      }
      if (!startTime) startTime = Date.now()
      const currentTime = Date.now()
      const elapsed = currentTime - startTime
      const x = initialX + (elapsed * xSpeed)
      const y = initialY + (ySpeed * elapsed) + (gravity * Math.pow(elapsed, 2))
      const rotation = rotationSpeed * elapsed
      ref.current.style.left = `${x}px`
      ref.current.style.top = `${y}px`
      ref.current.style.transform = `rotate(${rotation}deg)`

      if (x < -ballDiameter || x > (window.screen.width + ballDiameter)) {
        detached = true
      } else {
        window.requestAnimationFrame(animate)
      }
    }

    setTimeout(animate, 800 * Math.random())

    return () => {
      detached = true
    }
  })

  if (Math.random() < (1 / 3)) {
    return (
      <Basketball
        ref={ref}
        style={{ position: 'fixed', top: `${initialY}px`, left: `${initialX}px`, zIndex: 9999 }}
        width={ballDiameter}
        height={ballDiameter}
      />
    )
  } else if (Math.random() < (1 / 2)) {
    return (
      <Football
        ref={ref}
        style={{ position: 'fixed', top: `${initialY}px`, left: `${initialX}px`, zIndex: 9999 }}
        width={ballDiameter}
        height={ballDiameter}
      />
    )
  } else {
    return (
      <Volleyball
        ref={ref}
        style={{ position: 'fixed', top: `${initialY}px`, left: `${initialX}px`, zIndex: 9999 }}
        width={ballDiameter}
        height={ballDiameter}
      />
    )
  }
}

function Balls() {
  if (typeof window === 'undefined') return null

  const ballDiameter = Math.min(120, Math.round(window.innerWidth / 5.5))

  const navbarHeight = getComputedStyle(document.body).getPropertyValue('--navbar-height')
  const navBarPixels = parseInt(navbarHeight.match(/(\d+)px/)[1])

  const ballSize = Math.PI * 0.25 * Math.pow(ballDiameter, 2)

  const totalPixels = window.innerHeight * window.innerWidth

  const totalBalls = Math.min(Math.floor((totalPixels / 4.5) / ballSize), 50)

  return range(totalBalls).map(i => (
    <Ball
      key={i}
      ballDiameter={ballDiameter}
      navBarPixels={navBarPixels}
    />
  ))
}

export default Balls
