import React from "react"
import {StaticImage} from 'gatsby-plugin-image'
import Balls from "../components/balls"
import Layout from "../layout/layout"
import * as colors from '../colors.module.css'
import { CardHeader, MediumParagraph } from "../typography"
import { FullWidthVerticallyCenteredCard } from "../components/cards"
import * as styles from './thank-you.module.css'

/* const donationInDollars = (location) => {
  console.log('doremi')
  if (!location) return false
  console.log('xyz')
  const { searchParams } = new URL(location.href)
  console.log('abc')
  if (searchParams.get('currency') !== 'USD') return false
  console.log('123')
  return Number(searchParams.get('amount'))

} */

// https://dreamsportsafrica.org/thank-you/?id=17371349&amount=10.00&currency=USD&form_id=41021&form_name=Organizational+Support&first_name=Will&last_name=Weiss&email=will.weiss1230%40gmail.com&address=404+Putnam+Ave+%231&state=MA&city=Cambridge&country=US

const ThankYou = () => {

  React.useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      const params = new URLSearchParams(window.location.search)
      const amount = Number(params.get('amount'))

      if (amount) {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-810784927/dufMCMvrwYADEJ-xzoID',
          'value': amount,
          'currency': params.get('currency') || 'USD',
          'transaction_id': params.get('id') || '',
        });
      }
    }
  }, []);

  // const dollars = donationInDollars(location)

  // if (dollars) {
  //   // TODO: update copy to reflect the size of their donation
  //   // Also, use their name
  // }

  return (
    <Layout excludeDonateAndSignup>
      <Balls />
        <FullWidthVerticallyCenteredCard >
          <CardHeader className={colors.navy}>Thank You!</CardHeader>
          <div className={styles.cardContents}>
            <StaticImage src="../images/our-beliefs-smiling-kids-flexing.png" alt="smiling kids flexing muscles"/>
            <div>
              <MediumParagraph>Your support makes all of this possible.</MediumParagraph>
              <MediumParagraph>Thank you for helping build young African leaders with sound bodies, strong minds, and the skills and confidence to make a difference in their communities.</MediumParagraph>
            </div>
          </div>
        </FullWidthVerticallyCenteredCard>
    </Layout>
  )
}

export default ThankYou
